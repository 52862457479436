import { useEffect, useState } from 'react';
import track from 'src/lib/Analytics';
import { pxPush } from 'src/lib/Analytics/px';
import { generateRelativePathName } from 'src/lib/Analytics/util';
import Logger from 'src/lib/Utils/Logger';
import { useBodyContext } from '../../ContentWrapper/context';
import { CECAuthDataType, getCECAuthToken } from '../cecAuth';
import JSSHeroTypes from './types';

const JSSHero = ({
  headline,
  description,
  theme,
  gradients,
  image,
  primaryButton,
  secondaryButton,
  guid,
  modal,
}: JSSHeroTypes) => {
  const { dispatch } = useBodyContext();
  const [cecData, setCecData] = useState<CECAuthDataType>();
  const isDarkMode = theme.toLowerCase().includes('dark') || theme.toLowerCase().includes('blue');

  // Determining how many buttons should be rendered
  const hasPrimaryButton = primaryButton.value.href ? primaryButton.value.href.length > 0 : false;
  const isCECPrimaryButton = hasPrimaryButton && primaryButton.value.portaltokenauth;
  const hasSecondaryButton = secondaryButton.value.href
    ? secondaryButton.value.href.length > 0
    : false;

  useEffect(() => {
    if (isCECPrimaryButton) {
      try {
        const getCecToken = async () => {
          // hardcoding segment id of 2 now as that is the only one supported. This can be updated when more
          // segment ids become viable
          const cecAuthData = await getCECAuthToken(2);
          setCecData(cecAuthData as CECAuthDataType);
        };

        getCecToken();
      } catch (ex) {
        Logger(ex, { message: 'CEC SSO error' });
      }
    }
  }, []);

  const handleClick = (event: React.MouseEvent, href?: string) => {
    // Open modal
    if (modal?.id) {
      event.preventDefault();
      dispatch({ type: 'modalOpen', payload: { id: modal.id } });
    }

    // Send analytics
    const text = (event.target as HTMLElement).innerText;
    track.component({
      action: `${text}-|-${generateRelativePathName(href ?? '')}`,
      category: 'hero',
      event: 'event-click',
      label: headline ?? '',
    });

    pxPush({ eventName: 'hero', clickText: text });
  };

  return (
    <section className="relative 2xl:px-24 2xl:pt-24">
      <div className="relative container-5xl flex flex-col justify-center aspect-16/12 sm:aspect-16/9 md:aspect-16/7 lg:aspect-16/6 xl:aspect-16/5 px-24 sm:px-32 md:px-48 py-64 md:py-48">
        {image?.value?.src && (
          <img
            className="absolute top-0 left-0 object-cover object-right w-full h-full 2xl:rounded-lg bg-gray-light"
            src={`${image.value.src}?w=800&as=1&bc=ffffff`}
            srcSet={`${image.value.src}?w=800&as=1&bc=ffffff 800w,
          ${image.value.src}?w=1600&as=1&bc=ffffff 1600w`}
            sizes="(min-width: 768px) 1600px, 800px"
            alt=""
            width="1600"
            height="500"
            loading="lazy"
          />
        )}

        <div
          className={`absolute top-0 left-0 h-full w-full 2xl:rounded-l-lg md:w-3/4 bg-gradient-to-tr md:bg-gradient-to-r ${gradients[theme]} md:to-transparent opacity-80`}
          aria-hidden="true"
        ></div>
        <div className="relative w-full container-xs md:container-4xl">
          <div
            data-testid="jssHero-theme"
            className={`w-full md:w-1/2 text-center md:text-left ${
              isDarkMode ? 'text-white' : 'text-blue'
            }`}
          >
            <h2 className="text-3xl md:text-2xl-fixed xl:text-3xl" id={guid}>
              {headline}
            </h2>
            <p className="text-lg xl:text-xl mt-12 lg:mt-16">{description}</p>
            <div className="flex justify-center md:justify-start gap-16 lg:gap-24 mt-16 lg:mt-24">
              {hasPrimaryButton && (
                <a
                  className={`btn ${isDarkMode ? 'btn-primary-reversed' : 'btn-primary'} btn-md`}
                  href={
                    isCECPrimaryButton
                      ? `${primaryButton.value.href}&auth_token=${cecData?.access_token}&cecAuth=true`
                      : primaryButton.value.querystring
                      ? primaryButton.value.href + primaryButton.value.querystring
                      : primaryButton.value.href
                  }
                  id="hero-primary-action"
                  target={isCECPrimaryButton ? '_self' : primaryButton.value.target}
                  aria-labelledby={`hero-primary-action ${guid}`}
                  onClick={event =>
                    handleClick(
                      event,
                      isCECPrimaryButton
                        ? `${primaryButton.value.href}&auth_token=${cecData?.access_token}&cecAuth=true`
                        : primaryButton.value.querystring
                        ? primaryButton.value.href + primaryButton.value.querystring
                        : primaryButton.value.href
                    )
                  }
                >
                  {primaryButton.value.text}
                </a>
              )}
              {hasSecondaryButton && (
                <a
                  className={`btn ${
                    isDarkMode ? 'btn-secondary-reversed' : 'btn-secondary'
                  } btn-sm lg:btn-md`}
                  href={secondaryButton.value.href}
                  id="hero-secondary-action"
                  target={secondaryButton.value.target}
                  aria-labelledby={`hero-secondary-action ${guid}`}
                  onClick={event => handleClick(event, secondaryButton.value.href)}
                >
                  {secondaryButton.value.text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JSSHero;
